import React from 'react';
import OptimizedImage from '../../../utils/OptimizedImage';
import { Container, Icon, Text } from '../styles/HeroCard.styled';

const HeroCard = ({
  component: {
    heading,
    subhead,
    icon: { gatsbyImageData, url, alt, title },
  },
}) => (
  <Container>
    {url || gatsbyImageData ? (
      <Icon>
        <OptimizedImage src={url} image={gatsbyImageData} alt={alt || title} />
      </Icon>
    ) : null}
    <Text>
      {heading && <p className="heading">{heading}</p>}
      {subhead && (
        <div
          className="subhead"
          dangerouslySetInnerHTML={{ __html: subhead }}
        />
      )}
    </Text>
  </Container>
);

export default HeroCard;
