import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10.5px;
  padding: 32px;
  border-radius: 8px;
  background-color: ${colors.primary[50]};
`;
export const Icon = styled.div`
  width: 40px;
  height: 40px;
`;
export const Text = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .heading {
        ${font('text', 'xl', '700')}
        color: ${colors.gray[900]};
    }
    .subhead {
        ${font('text', 'md', '400')}
        color: ${colors.gray[900]};
    }
`;
