import { BACKGROUND_MAPPING } from './mappings';

export const determineBackgroundClass = component =>
  component?.background ? BACKGROUND_MAPPING[component?.background] : '';

export const removeBoldStr = str => {
  if (str.includes(getBoldStr(str))) {
    return str.replace(getBoldStr(str), '').replaceAll('**', '');
  }
  return str;
};

export const getBoldStr = str => {
  if (str.includes('**')) {
    return str.substring(str.indexOf('**') + 2, str.lastIndexOf('**'));
  }
  return null;
};

export function removeHeadingList(heading) {
  const arrRegex = /\[(.*?)\]/;
  const wordsReturn = heading.split(arrRegex);
  const splitHeading = [wordsReturn[0], wordsReturn[2]];
  return splitHeading;
}

export function splitHeadingList(heading) {
  const wordsReturn = heading.match(/\[(.*?)\]/);
  if (wordsReturn) {
    const wordList = wordsReturn[1].split(',');
    return wordList;
  }
}

export const handleLottieResize = (
  component,
  heroRef,
  lottieRef,
  isSmaller
) => {
  const handleResize = () => {
    if (heroRef.current && lottieRef.current) {
      if (!isSmaller && determineBackgroundClass(component) === 'bg-charcoal') {
        const heroRect = heroRef.current.getBoundingClientRect();
        lottieRef.current.style.height = `${heroRect.height}px`;
      } else {
        lottieRef.current.style.height = 'auto';
      }
    }
  };

  handleResize();
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
};
