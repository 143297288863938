import { isParagraph } from 'datocms-structured-text-utils';
import React from 'react';
import { renderRule, StructuredText } from 'react-datocms';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import { colors } from '../../../styles/atoms/colors';
import Link from '../../../utils/Link';
import Image from '../../../utils/OptimizedImage';
import {
  BodyContainer,
  MobileAppDisclaimer,
} from '../styles/HeroContent.styles';
import { getBoldStr, removeBoldStr } from '../utils/helpers';
import HeroCard from './HeroCard';
import HeroTestimonial from './HeroTestimonial';

const HeroBody = ({
  component: { __typename, background, body, showMobileAppDisclaimer },
  colorMap,
}) => {
  let buttonCounter = 0;
  const mainCtaColor =
    colorMap?.ctaColorOverride ||
    (background !== 'Light' ? 'secondary' : 'primary');
  const secondaryColor = background !== 'Light' ? 'outlineWhite' : 'tertiary';

  return (
    <BodyContainer
      copyColor={colorMap?.copy || colors.gray[600]}
      copyAccentColor={colorMap?.copyAccent || colors.gray[600]}
    >
      <StructuredText
        data={body}
        customRules={[
          renderRule(isParagraph, ({ children, key }) => {
            return (
              children[0] && (
                <p className="hero-paragraph" key={key}>
                  {children}
                </p>
              )
            );
          }),
        ]}
        renderInlineRecord={({ record }) => {
          switch (record.__typename) {
            case 'DatoCmsWebsiteImage':
              // eslint-disable-next-line no-plusplus
              buttonCounter++;
              return (
                <Link
                  openInNewTab
                  to={record?.link}
                  className="image-link d-inline-block"
                >
                  <Image
                    alt="download app"
                    src={record?.image.url}
                    loading="eager"
                  />
                </Link>
              );

            case 'DatoCmsCallToAction':
              // eslint-disable-next-line no-plusplus
              buttonCounter++;
              return (
                <div className="cta-btn-section" key={record?.label}>
                  <CallToAction
                    key={record?.id}
                    variant={
                      buttonCounter % 2 === 1 ? mainCtaColor : secondaryColor
                    }
                    value={record?.label}
                    link={record?.url}
                    size={record?.size}
                    icon={record?.icon}
                    scrollId={record?.scrollId}
                    reverse={record?.reverse}
                    popupReference={record?.popupReference}
                    componentName={__typename}
                  />
                </div>
              );

            case 'DatoCmsListItem':
              return (
                <div key={record?.id} className="structured-item">
                  {record?.iconImage && (
                    <Image
                      image={record?.iconImage?.gatsbyImageData}
                      src={record?.iconImage?.url}
                      alt="icon"
                    />
                  )}
                  <p className="list-label">{record.heading}</p>
                </div>
              );

            case 'DatoCmsComponentTestimonial':
              return <HeroTestimonial component={record} />;

            default:
              return null;
          }
        }}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case 'DatoCmsClaim':
              return (
                <div className="claim-container">
                  <div className="excerpt">
                    <span>{removeBoldStr(record?.excerpt)}</span>
                    <strong>{getBoldStr(record?.excerpt)}</strong>
                  </div>
                  <div className="disclaimer">{record?.disclaimer}</div>
                </div>
              );

            case 'DatoCmsMoleculeHeroCard':
              return <HeroCard component={record} />;

            default:
              return null;
          }
        }}
      />
      {showMobileAppDisclaimer && (
        <MobileAppDisclaimer color={colorMap.copyAccent}>
          A Shopmonkey subscription is required to use this app.
        </MobileAppDisclaimer>
      )}
    </BodyContainer>
  );
};

export default HeroBody;
